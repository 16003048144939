import { useQuery } from "react-query";

import api from "utils/api/api";

const GetAllInvoiceOfCustomer = async ({ customer_id }) => {
    try {
        const response = await api.get(`/api/v1/invoice/customer/${customer_id}`);

        return response.data;
    } catch (err) {
        throw err;
    }
};

const useGetAllInvoiceOfCustomer = ({ customer_id }) =>
    useQuery(["useGetAllInvoiceOfCustomer", customer_id], async () => await GetAllInvoiceOfCustomer({ customer_id }), {
        enabled: customer_id !== undefined,
    });

export default useGetAllInvoiceOfCustomer;
export { GetAllInvoiceOfCustomer };
