import { Layout, HeadPage, Compte, Table } from "components";

import useMainContext from "contexts/MainContext";

import useGetAllInvoiceOfCustomer from "utils/api/customer/invoice/GetAllInvoiceOfCustomer";
import { GetInvoicePdf } from "utils/api/customer/invoice/GetInvoicePdf";

const Factures = () => {
    const { customer } = useMainContext();

    const { data: invoices } = useGetAllInvoiceOfCustomer({ customer_id: customer.get?.id });

    const downloadInvoice = async (id) => {
        const invoice = await GetInvoicePdf({ invoice_id: id });

        // open a new tab with the pdf
        window.open(invoice?.data?.link, "_blank");
    };

    return (
        <Layout>
            <HeadPage isProfil={false} title="Factures" />

            <Table listHead={["", "Date", "Objet", "Montant", "Statut", ""]} options={{ isFixed: true }}>
                {invoices?.data?.map((item, index) => {
                    const data = {
                        ...item,
                        index: index,
                    };

                    return (
                        <Compte.Factures.TableRow
                            key={item?.id}
                            data={data}
                            handleDownload={() => downloadInvoice(item?.id)}
                        />
                    );
                })}
            </Table>
        </Layout>
    );
};

export default Factures;
